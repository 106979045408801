import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: '[home-page-card]',
  templateUrl: './home-page-card.component.html',
  styleUrls: ['./home-page-card.component.scss']
})
export class HomePageCardComponent implements OnInit {

  constructor() { }

  @Input()
  link: string;

  @Input()
  heading: string;

  @Input()
  content: string;

  @Input()
  logoSrc: string;

  @Input()
  disabled = false;

  ngOnInit() {
  }

}
