// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  company: {
    companyName: 'VIS Plzeň, s.r.o.',
    city: 'Plzeň',
    street: 'Farského 14',
    zipCode: '326 00',
    companyRegistrationNumber: '45330344',
    vatIdentificationNumber: 'CZ45330344',
    link: 'http://web.visplzen.cz/',
    linkText: 'www.visplzen.cz',
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
